<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <transaction :transaction="transaction" />
        </div>

        <div class="col-6 text-right">
          <base-button
              type="button"
              size="sm"
              @click="openValidateTransactionModal"
              class="elite-button add"
              v-if="transaction.status != 'VALIDATED'"
            >
              <span class="btn-inner--icon">
                <i class="far fa-check"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("COMMON.VALIDATE") }}
              </span>
          </base-button>
          <base-button
              type="button"
              size="sm"
              @click="cancelTransaction(transaction)"
              class="elite-button add"
              v-if="transaction.status != 'CANCELED'"
            >
              <span class="btn-inner--icon">
                <i class="far fa-times"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("TRANSACTIONS.CANCEL_TRANSACTION") }}
              </span>
          </base-button>
          <!--<base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_TRANSACTIONS)"
            @click="deleteTransaction"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_TRANSACTIONS)"
            @click="editTransaction"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>-->
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.STATUS") }}</dt>
          <dd>
            {{ $t(`TRANSACTIONS.STATUS_${transaction.status}`) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.PRICE") }}</dt>
          <dd>
            {{ $formatCurrency(transaction.price) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.ORGANIZATION_SELLER") }}</dt>
          <dd>
            <organization :organization="transaction.seller" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.LOCATION_SELLER") }}</dt>
          <dd>
            <location :location="transaction.sellerLocation" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.ORGANIZATION_BUYER") }}</dt>
          <dd>
            <organization :organization="transaction.buyer" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.LOCATION_BUYER") }}</dt>
          <dd>
            <location :location="transaction.buyerLocation" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("TRANSACTIONS.VEHICLE") }}</dt>
          <dd>
            <vehicle :vehicle="transaction.vehicle" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="transaction.created_at">
            {{ $timeZoneDateFormat(transaction.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="transaction.updated_at">
            {{ $timeZoneDateFormat(transaction.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
    <div
      v-if="showValidateTransactionModal"
      class="resizable-wrapper"
      v-bind:class="[showValidateTransactionModal ? 'show' : 'hide']"
    >
      <div class="resizable-wrapper-content" v-resizable-modal="'TRANSACTION'">
        <div class="resizable-wrapper-content-header">
          <h1>
            {{ $t("TRANSACTIONS.VALIDATE_TRANSACTION") }}
          </h1>
          <button class="close" @click="closeValidateTransactionModal">
            <i class="fal fa-times"></i>
          </button>
        </div>
        <div class="resizable-wrapper-content-body">
          <validate-transaction-component
            :transaction="transaction"
            :transactionId="transaction.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vehicle from "@/components/Vehicle";
import Location from "@/components/Location";
import Transaction from "@/components/Transaction";
import TransactionStatusBadgeComponent from "../../TransactionManagement/components/TransactionStatusBadgeComponent.vue";
import ValidateTransactionComponent from "../../TransactionManagement/components/ValidateTransactionComponent.vue";
import swal from "sweetalert2";
export default {
  name: "vehicle-active-transaction",

  components: {
    Location,
    Vehicle,
    Transaction,
    ValidateTransactionComponent,
    //TransactionStatusBadgeComponent,
  },

  props: ["transaction"],

  data() {
    return {
      showValidateTransactionModal: false,
    };
  },

  computed: {},

  created() {
    //console.log(this.transaction);
  },

  methods: {
    closeValidateTransactionModal(refresh) {
      this.showValidateTransactionModal = false;
    },
    openValidateTransactionModal() {
      this.showValidateTransactionModal = true;
    },
    cancelTransaction() {
      this.$emit("onCancelTransaction", this.transaction);
    },
    editTransaction() {
      this.$emit("onEditTransaction", this.transaction);
    },
    deleteTransaction() {
      this.$emit("onDeleteTransaction", this.transaction);
    },
  },

  mounted() {},

  watch: {
    transaction(transaction) {},
  },
};
</script>
